import React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Section from "../components/Section"
import Col from 'react-bootstrap/Col';
import Headline from "../components/Headline"
import VimeoEmbed from "../components/VimeoEmbed"
import { dateTimeToStr } from "../services/utility"

export default class ResourceTemplate extends React.Component {
  constructor(props) {
    super(props)
    let resource = this.props.data.contentfulVideoResource;
    let nowStr = dateTimeToStr(new Date());
    let canDownload = (resource.downloadLink && resource.downloadExpires && dateTimeToStr(resource.downloadExpires) > nowStr);
    this.state = {
      name: resource.name,
      vimeoId: resource.vimeoId,
      cinematicAspectRatio : resource.cinematicAspectRatio,
      canDownload: canDownload,
      showInResources: resource.showInResources,
      showInLeaders: resource.showInLeaders,
      downloadLink: resource.downloadLink,
      content: resource.notes ? resource.notes.childMarkdownRemark.html : (resource.description ? resource.description.childMarkdownRemark.html : "")
    }
  }

  render() {
    let showInResources = (typeof this.state.showInResources === 'undefined' || this.state.showInResources === null || this.state.showInResources === true) ? true : false;
    let showInLeaders = (typeof this.state.showInLeaders !== 'undefined' && this.state.showInLeaders === true) ? true : false;
    if(typeof window !== `undefined` && !showInResources && !showInLeaders) {
      window.location.href = '/resources';
      return null;
    }
    let ensureSignedIn = (!showInResources && showInLeaders);
    return (
      <Layout ensureSignedIn={ensureSignedIn}>
        <Seo title={this.state.name} />
        <Section className="white" useCol={false}>
          <Col xs={12} md={8}>
            <Headline>{this.state.name}</Headline>
          </Col>
          <Col xs={12} md={8}>
            <VimeoEmbed vimeoId={this.state.vimeoId} useCinema={this.state.cinematicAspectRatio} />
            { this.state.canDownload && 
              <div className="text-right"><a href={this.state.downloadLink} title={`Download ${this.state.name}`}><i class="fas fa-cloud-download-alt"> </i></a></div>
             }
            { this.state.content && <div className="fancy py-3" dangerouslySetInnerHTML={{
                __html: this.state.content,
              }}
            />}
          </Col>
          <Col xs={12} className="py-5">
            &nbsp;
          </Col>
          <Col xs={12} md={8} className="pt-5 text-center">
            <small>These resources are for private and/or teaching use only. They can not be sold, re-broadcast or used in any other fashion without written consent from On Eagles' Wings.</small>
          </Col>
        </Section>
      </Layout>
    )
  }
}

export const resourceFragment = graphql`
  fragment VideoResource on ContentfulVideoResource {
    name
    slug
    vimeoId
    cinematicAspectRatio
    vimeoThumbnail {
      childImageSharp {
        gatsbyImageData(
          width: 370
          height: 200
          )
      }
    }
    showInResources
    showInLeaders
    playInline
    downloadLink
    downloadExpires
    description {
      childMarkdownRemark {
        html
      }
    }
    notes {
      childMarkdownRemark {
        html
      }
    }
    sys {
      contentType {
        sys {
          id
        }
      }
    }
  }
`

export const pageQuery = graphql`
query ResourceBySlug($slug: String!) {
    contentfulVideoResource(slug: { eq: $slug }) {
      ...VideoResource
    }
  }
`
